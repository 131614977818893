import React, { useContext, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { MoltinContext } from '../context'
import { CartContext } from '../context'
import Vehicle from '../images/vehicle.png'

function Configurator() {
  const { moltin } = useContext(MoltinContext)
  const { addCustomItemToCart } = useContext(CartContext)
  const [baseOptions, setBaseOptions] = useState(null)
  let [totalAmount, setTotalAmount] = useState(0)
  const basePrice = 6900000

  async function getBaseOptions() {
    try {
      const { data, included } = await moltin.get(`flows/base_options/entries?include=product`)

      data.forEach(entry => {
        entry.product = included.option_product_ids.find(product => {
          return entry.relationships.option_product_id.data.id === product.id
        })
      })

      let groupedData = data.reduce((r, a) => {
        r[a.cat_section] = [...r[a.cat_section] || [], a];
        return r;
      }, {})

      setBaseOptions(groupedData)
    } catch (err) {
      throw new Error(err.message || 'Failed to retrieve base_options')
    }
  }

  function handleSelectChange(event) {
    removePreviousRule(event.target.getAttribute("previousrule"))
    applyNewRule(event.target)
    updateTotal()
  }

  function removePreviousRule(previousrule) {
    if (previousrule) {
      const ruleJson = JSON.parse(previousrule)
      if (ruleJson.requires) {
        ruleJson.requires.forEach(rule => {
          let select = document.getElementById(Object.keys(rule)[0])
          select.childNodes.forEach(option => {
            if (option.id !== Object.values(rule)[0]) {
              option.hidden = false
            }
          })
        })
      }
      if (ruleJson.excludes) {
        ruleJson.excludes.forEach(rule => {
          let select = document.getElementById(Object.keys(rule)[0])
          select.childNodes.forEach(option => {
            if (option.id === Object.values(rule)[0]) {
              option.hidden = false
            }
          })
        })
      }
    }
  }

  function applyNewRule(eventTarget) {
    if(eventTarget.value) {
      const optionJson = JSON.parse(eventTarget.value)
      if (optionJson.rules) {
        const ruleJson = JSON.parse(JSON.parse(optionJson.rules))
        
        if (ruleJson.requires) {
          ruleJson.requires.forEach(rule => {
            let select = document.getElementById(Object.keys(rule)[0])
            select.childNodes.forEach(option => {
              if (option.id === Object.values(rule)[0]) {
                if (option.selected === false) {
                  option.selected = true
                  const newEvent = { target: select }
                  handleSelectChange(newEvent)
                }
              } else {
                option.hidden = true
              }
            })
            eventTarget.setAttribute("previousrule", JSON.stringify(ruleJson))
          })
        }
        if (ruleJson.excludes) {
          ruleJson.excludes.forEach(rule => {
            let select = document.getElementById(Object.keys(rule)[0])
            select.childNodes.forEach(option => {
              if (option.id === Object.values(rule)[0]) {
                if (option.selected === true) {
                  const optionValue = JSON.parse(option.value)
                  option.selected = false
                  option.hidden = true
                  select.value = ""
                  
                  toast.error(
                    optionValue.cat_section + ": " + optionValue.option_label + " is not compatible, please select another option"
                  )
    
                  const newEvent = { target: select }
                  handleSelectChange(newEvent)
                } else {
                  option.hidden = true
                }
              }
            })
          })
        }
      }
    }
  }

  function updateTotal() {
    const selects = document.getElementsByTagName('select')
    const total = basePrice + Array.from(selects).reduce((accum, current) => {
      return accum + (current.value ? JSON.parse(current.value).product.price[0].amount : 0)
    }, 0)

    setTotalAmount(total)

    document.getElementById("totalPrice").textContent = "$" + (total * 0.01).toFixed(2)
  }

  function handleSubmit(event) {
    event.preventDefault()
    console.log(event.target)
    const customItem = createCustomItem(event.target.id === "preorder")
    addCustomItemToCart(customItem)
  }

  function createCustomItem(preorder) {
    const selects = document.getElementsByTagName('select')
    const description = "Base Model: Vestri EV - $69000\n\n" + Array.from(selects).reduce((accum, current) => {
      return accum + (current.value ? JSON.parse(current.value).cat_section + ": " 
        + JSON.parse(current.value).option_label + " - " + JSON.parse(current.value).product.meta.display_price.without_tax.formatted + "\n" : "")
    }, "") + "\nTotal: " + "$" + (totalAmount * 0.01).toFixed(2)
    const amount = preorder ? 100000 : totalAmount
    const sku = preorder ? "VESTRI_EV_PREORDER" : "VESTRI_EV"
    const item = {
      type: "custom_item",
      name: 'Vestri EV',
      sku,
      description,
      quantity: 1,
      price: {
        amount
      }
    }

    return item
  }

  useEffect(() => {
    getBaseOptions()
    setTotalAmount(basePrice)
  }, []);

  return (
    <form onSubmit={(e) => { e.preventDefault() }}>
      <div className="w-1/2 inline-block">
        {baseOptions && Object.keys(baseOptions).map(key => {
          return (
            <div className="mt-3">
              <div>{key}:</div>
              <div className="inline-flex relative">
                <select
                  id={key}
                  className="block bg-transparent appearance-none w-full border border-black hover:border-grey pl-4 pr-10 py-2 leading-tight rounded-none focus:outline-none"
                  onChange={(e) => { handleSelectChange(e); }}
                  previousrule=""
                  required
                >
                  <option id="selectPlaceholder" value="" selected>Select an option...</option>
                  {baseOptions[key].map(opt => (
                    <option id={opt.product.sku} key={opt.id} value={JSON.stringify(opt)} hidden={opt.hide} selected={opt.cat_default}>
                      {opt.option_label} - {opt.product.meta.display_price.without_tax.formatted}
                    </option>
                  ))}
                </select>

                <div className="pointer-events-none absolute pin-y pin-r flex items-center px-4 text-grey-darker">
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 9 14"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#131313"
                      strokeWidth="1.5"
                      transform="translate(1 1)"
                    >
                      <polyline points="0 9 3.5 12 7 9" />
                      <polyline points="0 3 3.5 0 7 3" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="vehicle-sticky w-1/2 inline-block align-top">
        <img src={Vehicle} alt="EV1" />
        <div className="text-center">Base Price: $69000</div>
      </div>
      <div className="text-center">
        <h2 className="inline-block">Total:&nbsp;</h2>
        <h2 className="inline-block" id="totalPrice">$69000</h2>
      </div>
      <div className="flex justify-end">
        <button
          id="preorder"
          type="submit"
          className="inline-block mr-3 appearance-none bg-blue border-black text-black hover:text-black px-4 py-3 leading-tight rounded-none focus:outline-none my-2 no-underline"
          onClick={(e) => { handleSubmit(e) }}
        >
          Preorder
        </button>
        <button
          id="buyNow"
          type="submit"
          className="inline-block appearance-none bg-black border border-black text-white hover:text-white px-4 py-3 leading-tight rounded-none focus:outline-none my-2 no-underline"
          onClick={(e) => { handleSubmit(e) }}
        >
          Buy Now
        </button>
      </div>
    </form>
  )
}

export default Configurator