import React from "react"

import PageTitle from '../components/PageTitle'
import Configurator from '../components/Configurator'

function ConfiguratorPage() {

  return (
    <React.Fragment>
      <PageTitle title="Build Your Vestri Electric Vehicle" />
      <Configurator />
    </React.Fragment>
  )
}

export default ConfiguratorPage
